@primary-color: #AA8A5F;
@secondary-color: black; 
@accent: #AA8A5F;
@link-color: @accent;

@font-face {
  font-family: 'GillSans';
  src: url('./assets/fonts/GillSans/GillSans.eot');
  src: local('Gill Sans'), url('./assets/fonts/GillSans/GillSans.woff') format('woff'),
  url('./assets/fonts/GillSans/GillSans.ttf') format('truetype'),
  url('./assets/fonts/GillSans/GillSans.otf') format('opentype'),
  url('./assets/fonts/GillSans/GillSans.svg') format('svg');
  font-style: normal;
}

@font-face {
  font-family: 'FuturaBook';
  src: url('./assets/fonts/futura/futura_book.eot');
  src: local('FuturaBook'), url('./assets/fonts/futura/futura_book.woff') format('woff'),
  url('./assets/fonts/futura/futura_book.ttf') format('truetype');
  font-style: normal;
}
