body {
  background-image: url('../src/bulgari/assets/images/background.jpg');
  background-size: contain;
  font-family: 'FuturaBook', 'Open Sans', sans-serif;
}

#loader-container span {
  border-color: @accent @accent transparent !important;
}

.App {
  text-align: center;
  color: @secondary-color;
  padding: 0 20%;
}

.App-logo {
  max-width: 100%; 
  height: 130px;
  display: block;
  margin: 0 auto;
  pointer-events: none;
}
  
@media (max-width: 820px) {
 .headerLogo {
   height: 10px;
   margin-right: 5px;
 }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding-top: 8%;
}

@media (max-width: 768px) {
  .ant-btn>.anticon+span,
  .ant-btn>span+.anticon {
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .ant-btn .anticon {
    margin-left: -6px;
  }
}

.login.ant-layout-header {
  height: 50px;
  background-color: white;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  font-weight: bold;
  position: absolute;
  width: 100%;
  left: 0;
  padding-right: 65%;
}

@media (max-width: 768px) {
  .login.ant-layout-header {
    padding-right: 6%;
    font-size: 67%;
    padding-top: 7px;
  }
}

@media (max-width: 820px) {
  .login.ant-layout-header {
    width: 100%;
    padding-right: 20px;
  }
}

h4 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  padding-top: 10px;
}

@media (max-width: 768px) {
  .h4 {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  img {
    vertical-align: middle;
    border-style: none;
    height: 11px;
  }
}

.App-link {
  color: @accent;
}

.ant-btn.ant-btn-default.exp{
  position: absolute;
  right: 0;
  margin-top: 15px;
  margin-right: 20px;
  z-index: 7;
}

.ant-table-cell .anticon.anticon-check{
  color: @secondary-color;
  background: @accent;
  min-height: 15px;
  border-radius: 52%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-table-tbody > tr > td{
  text-align: center;
}


.settings .ant-form .ant-row{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

section[class*="page-landing"] aside,
section[class*="page-bu-info"] aside {
  display: none;
}

