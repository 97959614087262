@headerToolbarTextColor: black;
@headerToolbarBackGround: white;
@imgTableMaxHeigth: 80px;
@goldColor: #AA8A5F;
@menucolor: #f2f2f2;

.site-layout #contentContainer {
  overflow: hidden;
  padding: 0 5%;
}

.headerToolbar{
  height: 50px ;
  background-color: @headerToolbarBackGround;
  color: @headerToolbarTextColor;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.headerBreadcrumbs {
  height: 50px ;
  background-color: grey;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.headerToolbar div{
  padding: 3px;
}

.headerToolbar img{
  height: 50px;
}

.ant-table-content img{
  max-height: @imgTableMaxHeigth;
}

.ant-table-thead > tr > th{
  background-color: @goldColor;
  color: @headerToolbarBackGround;
}

.ant-table-thead .ant-table-cell{
  text-align: center;
}

.ant-table-column-has-sorters:hover{
  background-color: @goldColor !important;
  color: @headerToolbarTextColor;
}

table > thead > tr > th.ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters{
  background-color: @goldColor !important;
  color: @headerToolbarTextColor;
}

aside.ant-layout-sider {
  background: @menucolor;
  position: fixed;
  height: 100%;
  right: 0;
  z-index: 99;
}


.ant-menu.ant-menu-dark {
  background: @menucolor;
  margin-top: 46px;

}


.ant-menu-dark .ant-menu-item {
  color: @goldColor;

  .ant-menu-title-content {
    color: @headerToolbarTextColor;

    i{
      font-style: normal;
    }
  }
}

.ant-menu-dark .ant-menu-item:hover {
  background-color: @goldColor;
  color: @headerToolbarTextColor;
}

/* seleceted item*/
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected * {
  color: @headerToolbarTextColor !important;
}

/* submenu scss rules */
.ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
  background: @goldColor !important;
}

.ant-menu-submenu-title i {
  font-style: normal;
}

.ant-menu-submenu-title:hover {
  background-color: @goldColor !important;
  color: @headerToolbarTextColor !important;

  .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
    background: @headerToolbarTextColor !important;
  }
}

aside .ant-layout-sider-trigger {
  background: @menucolor;
  position: absolute;
  top: 0;
  color:@goldColor;
}

.ant-form.ant-form-horizontal.splash{
  box-sizing: border-box;
  margin: 0;
  padding: 20%;
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  font-variant: tabular-nums;
  font-weight: bold;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}

.ant-btn.ant-btn-primary.enter{
  width: 35%;
  margin-top: 3%;
}

.dashboard .site-card-wrapper .ant-row {
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 0 10%;
  margin: 60px;
  height: 600px;
  padding-top: 14px;
  padding-bottom: 10px;
  border-radius: 0;
  transition: .5s all;
  color: #fff;
  min-width: 120%;
}

.dashboard .ant-card-body {
  height: 500px;
  padding-top: 50%;
  background-image: linear-gradient(to top, #553f23, #deca9f);
  box-shadow: 0px 0px 46px rgb(0 0 0 / 13%);
}

.dashboard .ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  background-color:#deca9f;
}

.dashboard .ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 0px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.ant-menu-submenu-title {
  color: @goldColor;
}

.ant-menu.ant-menu-sub.ant-menu-inline {
  background-color: @menucolor;
}

.ant-menu-submenu-arrow:before {
  background: @goldColor;
}

.cardDashboard {
  // background: @goldColor;
  background-image: -webkit-linear-gradient(top, #aa8a5f, #deca9f);
  background-image: -moz-linear-gradient(top, #aa8a5f, #deca9f);
  background-image: -o-linear-gradient(top, #aa8a5f, #deca9f);
  background-image: linear-gradient(to top, #aa8a5f, #deca9f);
  box-shadow: 0px 0px 46px rgba(0, 0, 0, 0.13);
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background:@menucolor;
}

.ant-modal.ant-modal-confirm.ant-modal-confirm-error{
 width: 30% !important;
}

.ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label{
  font-size: large;
}

.ant-table-content .ant-table-tbody tr:nth-child(odd){
  background-color: #f0f0f0;
}

.info-content {
  text-align: center;
}

.single-material {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.login {
  justify-content: space-evenly;
  text-align: center;
}

@media (max-width: 768px) {
  .ant-btn.ant-btn-primary.enter {
    width: 70%;
  }
}

@media (max-width: 820px) {
  .ant-col-16 {
    display: block;
    flex: 0 0 88.666667%;
    max-width: 120.000000%;
  }
}