#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
  color: black;
}

.site-layout .site-layout-background .ant-layout-header {
  background: #fff;
  color: black;
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-layout-header {
  background: black;
}

/* .ant-menu.ant-menu-dark {
  background: black;
  margin-top: 46px;
} */

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #af9064;
}

.page-dashboard .site-layout {
  background-image: url('../bulgari/assets/images/background.jpg');
  background-size: contain;
}

.dashboard .ant-picker {
  height: 40px;
  width: 100%;
}

.dashboard .ant-input {
  margin-right: 100px;
  height: 40px;
  border: 1px solid #e2e2e2;
  width: 100%;
}

.dashboard .ant-select {
  margin-right: 250px;
  height: 40px;
  width: 100%;
  padding: -1px 11px;
}

.dashboard .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 40px;
}

.dashboard .ant-form {
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 25px;
  align-items: center;
  justify-content: center;
}

.dashboard .container form>div {
  display: flex;
  justify-content: space-around;
}

.dashboard .container {
  border: solid #af9064;
  max-width: 80%;
  margin: 30px auto;
  position: relative;
}

.dashboard .ant-btn {
  height: 40px;
  margin: 5px;
  padding: 0 200px;
}

.dashboard .container form .buttonForm {
  position: absolute;
  padding-top: 13%;
}

.dashboard img {
  display: block;
  margin: auto
}



